<template>
  <h2>Need Help: WIP</h2>
</template>
<script>
import { NEED_HELP } from '@/constants'

export default {
  name: NEED_HELP,
}
</script>
